class MainService {

    #npsOptions = [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 }
    ];

    #destOptions = [
        { class: 'ico__yandex', platform: 'yandex', sort: 1 },
        { class: 'ico__gis', platform: '2gis', sort: 2 },
        { class: 'ico__google', platform: 'google', sort: 3 },
        { class: 'ico__otzovik', platform: 'otzovik', sort: 4 },
        { class: 'ico__flamp', platform: 'flamp', sort: 5 },
        { class: 'ico__recomended', platform: 'irecommend', sort: 6 },
        { class: 'ico__avito', platform: 'avito', sort: 7 },
        { class: 'ico__vk', platform: 'vkontakte', sort: 8 },
        { class: 'ico__zoon', platform: 'zoon', sort: 9 },
        { class: 'ico__prodoctorov', platform: 'prodoctorov', sort: 10 },
        { class: 'ico__docdoc', platform: 'docdoc', sort: 11 },
        { class: 'ico__yell', platform: 'yell', sort: 12 },
        { class: 'ico__website', platform: 'website', sort: 13 },
    ];


    /**
     *
     * @returns {[{value: number}]}
     */
    get npsOptions() {
        return this.#npsOptions;
    }

    /**
     *
     * @returns {[{sort: number, class: string, platform: string}]}
     */
    get destOptions() {
        return this.#destOptions;
    }

    prepareHeader = (headerData = {}) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();

        for (const key in headerData) {
            urlencoded.append(key, `${headerData[key]}`);
        }

        return {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
    }


}


const mainService = new MainService();
export default mainService;
